import * as React from "react"
import { Box, Heading, Text, Button, Center, Container } from '@chakra-ui/react';

export const Home = () => {

    return (
        <>
            <title>
                Shaarif's Site
            </title>
            <Container>
                <Center>
                    <Box textAlign="center" py={10} px={6}>
                        <Heading
                            display="inline-block"
                            as="h2"
                            size="2xl"
                            bgGradient="linear(to-r, cyan.400, cyan.600)"
                            backgroundClip="text">
                            Shaarif
                        </Heading>
                        <Text color={'gray.500'} mb={6}>
                            Hi my name is Shaarif
                        </Text>
                    </Box>
                </Center>
            </Container>
        </>
    );
}