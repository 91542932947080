import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme, Container, Heading,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Home } from "./Home/Home";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Container size="1200px">
      <Home></Home>
    </Container>
  </ChakraProvider>
)
